@import 'src/assets/scss/functions';
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';
@import 'src/assets/scss/buttons';

.userOnboardingWrapper {
  & > * {
    z-index: 10001;
    position: relative;
  }

  &:before {
    background-color: $black;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0.7;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
  }
}

.userOnboarding {
  position: relative;
  border: none;
  padding: $spacer-lg;
  box-shadow: $box-shadow-lg;
  margin: 0 auto $spacer-lg;
  width: 550px;
  max-width: calc(100vw - #{$spacer-lg * 2});

  &.fixed {
    position: absolute;
    top: $spacer-xl;
    left: 50%;
    transform: translateX(-50%);
  }

  &.isMutating > * {
    opacity: 0.5;
  }

  input::placeholder {
    color: $gray-400;
  }
}

.logo {
  margin: $spacer-lg auto;
  max-width: 175px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.otherCategoryButton {
  @extend .btn;
  @extend .btn-gray-50;
}

.categoryCheckbox input[type='checkbox'] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;

  &:focus + label {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &:checked + label {
    @extend .btn-red-500;
  }
}

.categoryCheckbox label {
  @extend .btn;
  @extend .btn-gray-50;
  cursor: pointer;
  margin: 0;
}

.categoryInput {
  display: inline-block;
  margin: 0;
  padding: 0;
  :global(input.form-control) {
    background-color: $gray-50;
    margin: 0;
  }
}

.competencesContainer {
  display: flex;
  gap: $spacer-sm;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.userOnboardingStep {
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .image {
    margin: $spacer-lg 0;
    border-radius: $border-radius;
  }
  .illustration {
    max-width: 332px;
    margin-bottom: $spacer-lg;
  }

  .logoHeart {
    margin: $spacer-lg auto 0;
    width: 40px;
  }

  .mainTitle {
    font-size: $font-size-xl;
    text-align: center;
    font-weight: bold;
  }

  .headingLarge {
    font-size: $font-size-xl;
  }

  .headingMedium {
    font-size: $font-size-lg;
  }

  .headingSmall {
    font-weight: normal;
    color: $gray-400;
    margin-bottom: $spacer-sm;
    font-size: $font-size-base;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }

  .smallPrint {
    font-size: $font-size-sm;
  }

  .fieldset {
    margin: $spacer 0;
  }

  .legend {
    font-size: $font-size-base;
    margin-bottom: $spacer-sm;
  }
}

.userOnboardingStep2,
.userOnboardingStep3 {
  .form-group {
    padding: 0;
  }
}

.userOnboardingStep4 {
  background-color: $white;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-lg;
  margin: 0 auto;
  padding-top: $spacer-lg;
  width: 300px;
  max-width: calc(100vw - #{$spacer-lg * 2});
  overflow: hidden;
}

.passwordRulesOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: $spacer-xl $spacer $spacer;
  border-radius: 3px;
  width: 274px;
  background-color: $white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.passwordRules {
  &IconWrapper {
    margin: 2rem auto;
  }
  &Icon {
    font-size: 70px;
    color: $red-100;
  }
  &Title {
    margin: 0 auto;
    font-weight: bold;
    margin-bottom: 0;
    color: $gray-700;
  }
  &List {
    color: $gray-500;
    padding-left: 0;
    list-style-type: none;
    font-size: 0.875rem;
  }
  &ListItem {
    margin-top: 1rem;
    display: flex;

    &IconWrapper {
      min-width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $red-500;
      }
    }
  }
}

.mobileOnlyBlock {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.mobileOnlyFlex {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.desktopOnlyBlock {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
.desktopOnlyFlex {
  display: flex !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.resetPassword {
  @extend .passwordRulesOuterContainer;
  padding-top: $spacer-lg;
  border-radius: $border-radius-lg;
  overflow: hidden;
}

.form {
  margin: $spacer 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(100%, 1fr));
  gap: $spacer;
  position: relative;
  min-height: 200px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  :global(.form-group) {
    padding: 0;
    margin: 0;

    label {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
