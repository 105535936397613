@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/buttons';
@import './components/UserOnboarding/UserOnboarding.module.scss';

.backgroundImage {
  pointer-events: none;
  position: absolute;
  height: auto;
  width: 37vw;
  max-width: 480px;
  z-index: -1;
  left: $spacer;
  bottom: $spacer;
}

.loginContainer {
  max-width: 425px;
  position: relative;
  padding-bottom: $spacer-lg;
  background-color: rgba($green-50, 0.9);
  border-bottom: 1px solid rgba($green-100, 0.3);
  border-radius: 0 0 $border-radius $border-radius;
}

.passwordContainer {
  @extend .resetPassword;
  margin: $spacer-xl auto;
}

.languageSwitcherWrapper {
  position: relative;
  padding-bottom: $spacer-sm;
  border-radius: $border-radius;
  background-color: $gray-50;
  box-shadow: $box-shadow-sm;
  z-index: 1;
  transition: margin-bottom 0.3s 0.3s ease-in-out, background-color 1s 0.4s;
  &:hover {
    // margin bottom will be set on element in js, and on hover we
    // need to override this
    margin-bottom: 5px !important;
    background-color: $white;
    transition: margin-bottom 0.15s ease-in-out, background-color 0.2s;
  }
}

.LanguageSwitcher {
  list-style: none;
  margin: 0;
  padding: 0;
}

.languageToggleButton {
  width: 100%;
  text-align: left;
  display: block;
  padding: $spacer-sm $spacer;
  cursor: default;
}

.languageToggleIcon {
  margin-right: $spacer-sm;
}
